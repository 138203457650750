<template>
  <div>
    <b-card>
      <div class="d-flex justify-content-center">
        <div
          class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
        >
          <feather-icon icon="HomeIcon" size="35" color="white" />
        </div>
        <h3 class="mr-5 text">Sender's Details</h3>
        <div class="w-75">
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_first_name }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_last_name }}
            </p>
          </div>
          <div class="mb-2">
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_phone_number }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_unit_number }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_address_line_1 }}
            </p>
          </div>
          <div class="mb-2">
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_address_line_2 }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_postcode }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_area }}
            </p>
          </div>
          <div class="d-flex mb-2">
            <p type="text" class="form-control mr-2 d-flex align-items-center">
              {{ form.sender_state }}
            </p>
            <p type="text" class="form-control d-flex align-items-center">
              {{ form.sender_country }}
            </p>
          </div>
        </div>
      </div>
      <div class="w-100">
        <div class="d-flex">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="ShoppingCartIcon" size="35" color="white" />
          </div>
          <h3 class="text">Cart</h3>
        </div>
        <MyTable :columns="columns" :rows="rows" :is-loading="isLoading">
          <template v-slot="{ props }">
            <span v-if="props.column.field === 'unit_price'">
              {{ $helpers.formatTotal(props.row.unit_price) }}
            </span>
            <span v-else-if="props.column.field === 'tax'">
              {{ $helpers.formatTotal(props.row.tax) }}
            </span>
            <span v-else-if="props.column.field === 'sub_total'">
              {{ $helpers.formatTotal(props.row.sub_total) }}
            </span>
            <span v-else-if="props.column.field === 'grand_total'">
              {{ $helpers.formatTotal(props.row.grand_total) }}
            </span>
            <span v-else>
              {{ props.formattedRow[props.column.field] }}
            </span>
          </template>
        </MyTable>
      </div>
      <div>
        <div class="d-flex">
          <div
            class="rounded-circle icon d-flex icon align-items-center justify-content-center mr-1"
          >
            <feather-icon icon="DollarSignIcon" size="35" color="white" />
          </div>
          <h3 class="text">Total</h3>
        </div>
        <div class="d-flex align-items-center justify-content-between ml-5">
          <b-row align-h="between">
            <b-col>
              <div>
                <b-row>
                  <b-col><h4>Voucher Code</h4></b-col>
                  <b-col>
                    <select
                      v-model="voucher"
                      class="form-control form-select rounded w-50"
                    >
                      <option value="">No Voucher Code</option>
                      <option
                        v-for="voucherList in voucherList"
                        :key="voucherList.id"
                        :value="voucherList"
                      >
                        {{ voucherList.voucher_code }}
                      </option>
                    </select></b-col
                  >
                </b-row>
                <b-row>
                  <b-col><h4>Sub Total</h4></b-col>
                  <b-col>{{
                    $helpers.formatTotal(this.form.pricing.sub_total)
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col><h4>Voucher Discount</h4></b-col>
                  <b-col>RM 0.00</b-col>
                </b-row>
                <b-row>
                  <b-col><h4>Tax Charge</h4></b-col>
                  <b-col>{{
                    $helpers.formatTotal(this.form.pricing.total_tax)
                  }}</b-col>
                </b-row>
                <b-row>
                  <b-col><h4>Grand Total</h4></b-col>
                  <b-col>{{
                    $helpers.formatTotal(this.form.pricing.grand_total)
                  }}</b-col>
                </b-row>
              </div></b-col
            >
            <b-col md="2" offset-md="4">
              <div>
                <h4>WE ACCEPT</h4>
                <img
                  :src="require('@/assets/images/logo/visa.png')"
                  class="img-fluid w-75 mr-5 pr-2"
                /></div
            ></b-col>
          </b-row>
        </div>
      </div>
    </b-card>
    <div class="d-flex justify-content-center align-items-center mt-5">
      <b-button
        v-b-modal.modal-center
        variant="light"
        class="box-shadow-1 px-5 mr-3 w-25"
        @click="onBack"
        >Back</b-button
      >
      <b-button
        v-b-modal.modal-center
        variant="gradient-primary"
        class="box-shadow-1 px-5 w-25"
        @click="onSubmit"
        >Submit</b-button
      >
    </div>
  </div>
</template>

<script>
import MyTable from '@/views/components/MyTable.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';

export default {
  components: {
    MyTable,
  },

  data() {
    return {
      isLoading: false,
      rangeDate: null,
      subTotal: '',
      taxCharge: '',
      voucherList: [],
      voucher: '',
      pricing: {},
      form: {},
      double_charge: '',
      columns: [
        {
          label: 'Shipment',
          field: 'shipment',
        },
        {
          label: "Recipient's Name",
          field: 'recipient_name',
        },
        {
          label: 'Phone Number',
          field: 'phone_number',
        },
        {
          label: 'Unit Number',
          field: 'unit_number',
        },
        {
          label: 'Address Line 1',
          field: 'address_line_1',
        },
        {
          label: 'Address Line 2',
          field: 'address_line_2',
        },
        {
          label: 'Postcode',
          field: 'postcode',
        },
        {
          label: 'Area',
          field: 'area',
        },
        {
          label: 'State',
          field: 'state',
        },
        {
          label: 'Country',
          field: 'country',
        },
        {
          label: 'Delivery Date',
          field: 'delivery_date',
        },
        {
          label: 'Product Category',
          field: 'product_category',
        },
        {
          label: 'Size',
          field: 'size',
        },
        {
          label: 'Weight',
          field: 'weight',
        },
        {
          label: 'Quantity',
          field: 'quantity',
        },
        {
          label: 'Unit Price',
          field: 'unit_price',
        },
        {
          label: 'Tax',
          field: 'tax',
        },
        {
          label: 'Sub Total',
          field: 'sub_total',
        },
        {
          label: 'Grand Total',
          field: 'grand_total',
        },
      ],
      rows: [],
    };
  },
  created() {
    if (this.$route.params.data) {
      this.form = this.$route.params.data;
      this.rows = this.form.deliveries;
      for (let x = 0; x < this.rows.length; x++) {
        this.rows[x].unit_price = this.form.pricing.data[x].unit_price;
        this.rows[x].tax = this.form.pricing.data[x].tax;
        this.rows[x].sub_total = this.form.pricing.data[x].sub_total;
        this.rows[x].grand_total = this.form.pricing.data[x].grand_total;
      }
    }
  },
  methods: {
    onSubmit() {
      this.$router.push({
        name: 'bulk-order-paymentmethod',
        params: { data: this.form },
      });
    },
    onBack() {
      this.$router.push({
        name: 'bulk-order',
        params: {
          data: this.form,
        },
      });
    },
  },
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/vue-good-table.scss';
.icon {
  background-color: #0f4392;
  height: 50px;
  width: 50px;
}
.text {
  width: 15rem;
}
.notActive {
  background-color: rgba(211, 18, 100, 0.58);
}
.tableBG {
  background-color: #f7f8fc;
}
.totalText {
  margin-top: 1vh;
  width: 9vw;
}
</style>
